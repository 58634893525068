import { FLAG_CONTENT } from '@/core/constants/enums';
import { ROUTE_NAME } from '@/core/constants';

const flag = {
  methods: {
    getFlagRouteName(content) {
      let routeNames = {
        [FLAG_CONTENT.USER]: ROUTE_NAME.FLAG_MEMBER,
        [FLAG_CONTENT.CASTING]: ROUTE_NAME.FLAG_CASTING_POST,
        [FLAG_CONTENT.NEWSFEED]: ROUTE_NAME.FLAG_COMMUNITY_POST,
        [FLAG_CONTENT.REVIEW]: ROUTE_NAME.FLAG_REVIEW
      };

      return routeNames[content];
    }
  }
};

export default flag;
