<template>
  <FlagList :content="content" :extra="extraColumns"></FlagList>
</template>

<script>
  import FlagList from '@/views/components/flag/FlagList';
  import { FLAG_CONTENT } from '@/core/constants/enums';

  export default {
    name: 'FlagCommunityPostList',
    components: {
      FlagList
    },
    data: () => ({
      content: FLAG_CONTENT.NEWSFEED,
      extraColumns: [
        {
          key: 'reportedBy',
          name: 'reportedBy',
          active: true,
          sortable: true
        },
        {
          key: 'postOwner',
          name: 'postOwner',
          active: true,
          sortable: true
        },
      ]
    })
  };
</script>

<style></style>
