<template>
  <div>
    <AppSmartList
      :breadcrumbData="breadcrumbData"
      :accessRights="accessRights"
      :filters="filters"
      :actions="actions"
      :tableHeaders="headers"
    ></AppSmartList>
  </div>
</template>

<script>
  import AppSmartList from '@/views/components/bases/smart/AppSmartList.vue';
  import {
    ROUTE_NAME,
    MODULE,
    ACTION,
    SMART_FORM_FIELD_TYPE,
    APP_COMPONENT_NAME
  } from '@/core/constants';
  import { FLAG_STATUS, FLAG_CONTENT } from '@/core/constants/enums';
  import { i18nHelper } from '@/core/utils';
  import { listService } from '@/core/services';
  import flagMixin from '@/core/mixins/flag.mixin';
  import {
    FLAG_GET_FLAGS,
    FLAG_INITIAL_GET_FLAGS_STATE,
    FLAG_EXPORT_FLAG_TO_EXCEL,
    FLAG_INITIAL_EXPORT_FLAG_TO_EXCEL_STATE
  } from '@/core/store/flag.module';

  export default {
    name: 'FlagList',
    components: {
      AppSmartList
    },
    mixins: [flagMixin],
    props: {
      content: {
        type: String,
        required: true
      },
      extra: {
        type: Array,
        default: () => ([])
      }
    },
    data: () => ({
      breadcrumbData: {
        breadcrumbs: []
      },
      accessRights: {
        moduleName: MODULE.FLAG,
        actions: {
          read: ACTION.FLAG_READ,
          update: ACTION.FLAG_UPDATE
        }
      },
      filters: [
        {
          label: 'status',
          key: 'status',
          value: null,
          options: listService.getFlagStatusList(),
          optionLabel: 'text',
          type: SMART_FORM_FIELD_TYPE.SELECT
        }
      ],
      actions: {
        viewList: {
          module: 'flag',
          state: 'flags',
          action: FLAG_GET_FLAGS,
          initialStateAction: FLAG_INITIAL_GET_FLAGS_STATE,
          title: i18nHelper.getMessage('label.getFlags')
        },
        exportExcel: {
          module: 'flag',
          state: 'exportFlagToExcel',
          action: FLAG_EXPORT_FLAG_TO_EXCEL,
          initialStateAction:
            FLAG_INITIAL_EXPORT_FLAG_TO_EXCEL_STATE,
          download: true,
          title: i18nHelper.getMessage('label.exportExcel')
        },
        viewDetails: {
          routeName: null
        }
      },
    }),
    created() {
      this.initFlagList();
      // specify the name of the excel file
      this.actions.exportExcel.fileName = this.$route.name;
    },
    computed: {
      headers() {
        return  [
        {
          key: 'no',
          name: 'no',
          active: true,
          sortable: true
        },
        {
          key: 'type',
          name: 'type',
          active: true,
          sortable: true
        },
        {
          key: 'status',
          name: 'status',
          active: true,
          sortable: true,
          class: 'text-capitalize',
          layout: APP_COMPONENT_NAME.APP_STATUS_LABEL,
          config: {
            enumKey: 'enumFlagStatus',
            statusColor: (value) => {
              let color = {
                [FLAG_STATUS.PENDING]: 'primary',
                [FLAG_STATUS.APPROVED]: 'success',
                [FLAG_STATUS.REJECTED]: 'danger'
              };

              return color[value];
            }
          }
        },
        ...this.extra,
        {
          key: 'createdAt',
          name: 'createdAt',
          active: true,
          sortable: true,
          sortField: 'createdAtTimeStamp'
        },
        {
          key: 'updatedAt',
          name: 'updatedAt',
          active: true,
          sortable: true,
          sortField: 'updatedAtTimeStamp'
        },
        {
          key: 'actions',
          name: 'actions',
          active: true,
          action: true,
          layout: APP_COMPONENT_NAME.APP_TABLE_ACTIONS
        }
      ]
      }
    },
    methods: {
      getFlagDetailsRouteName() {
        let routeNames = {
          [FLAG_CONTENT.USER]: ROUTE_NAME.FLAG_MEMBER_DETAILS,
          [FLAG_CONTENT.CASTING]: ROUTE_NAME.FLAG_CASTING_POST_DETAILS,
          [FLAG_CONTENT.NEWSFEED]: ROUTE_NAME.FLAG_COMMUNITY_POST_DETAILS,
          [FLAG_CONTENT.REVIEW]: ROUTE_NAME.FLAG_REVIEW_DETAILS
        };

        return routeNames[this.content];
      },
      initFlagList() {
        var content = this.content;
        var breadcrumbData = {
          breadcrumbs: [
            {
              title: i18nHelper.getMessage('label.flag'),
              route: { name: ROUTE_NAME.FLAG }
            },
            {
              title: i18nHelper.getMessage(`enumFlagContent.${content}`),
              route: { name: this.getFlagRouteName(content) }
            },
            {
              title: i18nHelper.getMessage(`enumFlagContentList.${content}`)
            }
          ]
        };

        this.breadcrumbData = breadcrumbData;

        this.filters.push({
          label: 'content',
          key: 'content',
          value: content,
          isHidden: true
        });

        this.actions.viewDetails.routeName = this.getFlagDetailsRouteName();
      }
    }
  };
</script>

<style></style>
